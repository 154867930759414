import React from "react";
import theme from "theme";
import { Theme, Em, Text, Box, Icon, Link, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { MdLocationOn, MdPhone, MdMailOutline } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"contacts"} />
		<Helmet>
			<title>
				 Ocean's Embrace Charters
			</title>
			<meta name={"description"} content={"Ocean's Embrace Charters спеціалізується на створенні розкішних і незабутніх чартерів яхт. "} />
			<meta property={"og:title"} content={" Ocean's Embrace Charters"} />
			<meta property={"og:description"} content={"Ocean's Embrace Charters спеціалізується на створенні розкішних і незабутніх чартерів яхт. "} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6699200a9710450018a16614/images/1-4.jpg?v=2024-07-18T14:03:12.506Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6699200a9710450018a16614/images/1-4.jpg?v=2024-07-18T14:03:12.506Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6699200a9710450018a16614/images/1-4.jpg?v=2024-07-18T14:03:12.506Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6699200a9710450018a16614/images/1-4.jpg?v=2024-07-18T14:03:12.506Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6699200a9710450018a16614/images/1-4.jpg?v=2024-07-18T14:03:12.506Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6699200a9710450018a16614/images/1-4.jpg?v=2024-07-18T14:03:12.506Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6699200a9710450018a16614/images/1-4.jpg?v=2024-07-18T14:03:12.506Z"} />
		</Helmet>
		<Components.Marque />
		<Components.Header />
		<Section padding="80px 0 80px 0" background="--color-dark" color="--lightD2" quarkly-title="Contacts-8">
			<Override slot="SectionContent" flex-direction="row" align-items="flex-start" lg-flex-direction="column" />
			<Box
				min-width="100px"
				min-height="100px"
				width="40%"
				lg-width="100%"
				display="flex"
				flex-direction="column"
				justify-content="center"
				lg-margin="0px 0 30px 0px"
				align-items="flex-start"
				sm-margin="0px 0px 0 0px"
				lg-padding="0px 180px 0px 0px"
				sm-padding="0px 0 0px 0px"
				md-padding="0px 0px 0px 0px"
			>
				<Text margin="0px 0px 20px 0px" font="normal 600 38px/1.2 --fontFamily-sansHelvetica" color="--light">
					<Em font-weight="normal">
						Сплануйте свою подорож
					</Em>
				</Text>
				<Text
					margin="0px 0px 50px 0px"
					font="normal 300 16px/1.5 --fontFamily-sansHelvetica"
					color="--lightD2"
					lg-margin="0px 0px 20px 0px"
					md-width="100%"
				>
					Відправляйтеся в розкішну пригоду, розроблену спеціально для вас, з Ocean's Embrace Charters. Зв'яжіться з нами, щоб дізнатися, як ми можемо втілити в реальність морську подорож вашої м
				</Text>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="16px 24px"
				margin="0px 0 0 10%"
				lg-grid-template-columns="repeat(2, 1fr)"
				lg-grid-gap="36px 34px"
				md-grid-template-columns="1fr"
				flex-direction="column"
				width="50%"
				lg-margin="0px 0 0 0"
				lg-width="100%"
			>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					flex-direction="row"
					border-color="--color-lightD2"
					border-radius="4px"
					align-items="center"
					margin="0px 0px 30px 0px"
					lg-margin="0px 0px 10px 0px"
					sm-margin="0px 0px 0 0px"
				>
					<Icon
						category="md"
						icon={MdLocationOn}
						size="54px"
						margin="0px 20px 0 0px"
						color="--darkL1"
						background="--color-light"
						padding="12px 12px 12px 12px"
						border-radius="50%"
					/>
					<Box
						min-width="10px"
						min-height="10px"
						display="flex"
						flex-direction="column"
						align-items="flex-start"
						md-justify-content="center"
					>
						<Link
							href="tel:+9877654321223"
							color="--light"
							text-decoration-line="initial"
							font="normal 500 25px/1.5 --fontFamily-sansHelvetica"
							display="block"
							margin="0px 0px 5px 0px"
							sm-font="normal 500 22px/1.5 --fontFamily-sansHelvetica"
						>
							Dniprovska Embankment,{" "}
							<br />
							10, Kyiv, 02081
						</Link>
					</Box>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					flex-direction="row"
					border-color="--color-lightD2"
					border-radius="4px"
					align-items="center"
					margin="0px 0px 30px 0px"
					lg-margin="0px 0px 10px 0px"
					sm-margin="0px 0px 0 0px"
				>
					<Icon
						category="md"
						icon={MdPhone}
						size="54px"
						margin="0px 20px 0 0px"
						color="--darkL1"
						background="--color-light"
						padding="12px 12px 12px 12px"
						border-radius="50%"
					/>
					<Box
						min-width="10px"
						min-height="10px"
						display="flex"
						flex-direction="column"
						align-items="flex-start"
						md-justify-content="center"
					>
						<Link
							href="0952071650"
							color="--light"
							text-decoration-line="initial"
							font="normal 500 25px/1.5 --fontFamily-sansHelvetica"
							display="block"
							margin="0px 0px 5px 0px"
							sm-font="normal 500 22px/1.5 --fontFamily-sansHelvetica"
						>
							0952071650
						</Link>
					</Box>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					flex-direction="row"
					border-color="--color-lightD2"
					border-radius="4px"
					align-items="center"
				>
					<Icon
						category="md"
						icon={MdMailOutline}
						size="54px"
						margin="0px 20px 0 0px"
						color="--darkL1"
						background="--color-light"
						padding="12px 12px 12px 12px"
						border-radius="50%"
					/>
					<Box
						min-width="10px"
						min-height="10px"
						display="flex"
						flex-direction="column"
						align-items="flex-start"
						md-justify-content="center"
					>
						<Link
							href="info@jubilatedix.com"
							color="--light"
							text-decoration-line="initial"
							font="normal 500 25px/1.5 --fontFamily-sansHelvetica"
							display="block"
							margin="0px 0px 5px 0px"
							sm-font="normal 500 22px/1.5 --fontFamily-sansHelvetica"
						>
							info@jubilatedix.com
						</Link>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Footer>
			<Override slot="text3">
				Dniprovska Embankment,{" "}
				<br />
				10, Kyiv, 02081
			</Override>
			<Override slot="link3">
				0952071650
			</Override>
			<Override slot="link4" />
			<Override slot="text" />
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65b35f058e2e8e002176cca3"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});